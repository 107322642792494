<p class="intro">First, please make sure you have an account...</p>

<div class="row">
  <div class="col">

    <div class="card">
      <h5 class="card-header">Sign Up</h5>
      <div class="card-body">

        <div *ngIf="signUpError" class="alert alert-warning" role="alert">{{signUpError}}</div>

        <form name="form" (ngSubmit)="signUpForm.form.valid && signUp()" #signUpForm="ngForm">
          <div class="form-group">
            <label for="signUpEmail">Email</label>
            <input type="text" class="form-control" id="signUpEmail" name="email" [(ngModel)]="signUpValues.email" required>
          </div>

          <div class="form-group">
            <label for="signUpPassword">Password</label>
            <input type="password" class="form-control" id="signUpPassword" name="password" [(ngModel)]="signUpValues.password" required>
          </div>

          <button type="submit" class="btn">Sign Up</button>

        </form>

      </div>
    </div>

  </div>
  <div class="col">

    <div class="card">
      <h5 class="card-header">Sign In</h5>
      <div class="card-body">

        <div *ngIf="signInError" class="alert alert-warning" role="alert">{{signInError}}</div>

        <div *ngIf="signInError" class="alert alert-warning" role="alert">Forget your password? <a (click)="resetPassword()">Click here to reset.</a></div>

        <div *ngIf="signInResetPasswordResponse" class="alert alert-warning" role="alert">{{signInResetPasswordResponse}}</div>

        <form name="form" (ngSubmit)="signInForm.form.valid && signIn()" #signInForm="ngForm">
          <div class="form-group">
            <label for="signInEmail">Email</label>
            <input type="text" class="form-control" id="signInEmail" name="email" [(ngModel)]="signInValues.email" required>
          </div>

          <div class="form-group">
            <label for="signInPassword">Password</label>
            <input type="password" class="form-control" id="signInPassword" name="password" [(ngModel)]="signInValues.password" required>
          </div>

          <button type="submit" class="btn">Sign In</button>

        </form>

      </div>
    </div>

  </div>
</div>
