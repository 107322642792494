import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puzzle-sign',
  templateUrl: './puzzle-sign.component.html',
  styleUrls: ['./puzzle-sign.component.less']
})
export class PuzzleSignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
