import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-ship',
  templateUrl: './ship.component.html',
  styleUrls: ['./ship.component.less']
})
export class ShipComponent implements OnInit {



  didShowMoreInfo = false
  moreInfoIsVisible = false



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
  }

  shadeDisplayClass() {
    if (!this.didShowMoreInfo) { return '' }
    return this.moreInfoIsVisible ? 'moreInfoShadeIsVisible' : 'moreInfoShadeIsHidden'
  }

  moreInfoDisplayClass() {
    if (!this.didShowMoreInfo) { return '' }
    return this.moreInfoIsVisible ? 'moreInfoIsVisible' : 'moreInfoIsHidden'
  }

  showMoreInfo() {
    this.didShowMoreInfo = true
    this.moreInfoIsVisible = true
  }

  hideMoreInfo() {
    this.moreInfoIsVisible = false
  }

}
