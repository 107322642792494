<div class="rows">
  <div class="row rowImages">
    <div class="col-6">
      <div class="treeRingsImage"></div>
    </div>
    <div class="col-6">
      <div class="treeRingsNoteImage"></div>
    </div>
  </div>
  <div class="row rowAnswer">
    <div class="col">

      <div class="clue">

        <p class="question">How old is this tree (in years)?</p>

        <p><input [(ngModel)]="answer" /></p>

        <p class="button"><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

        <p *ngIf="currentUserService.currentUserData.treeRingsPuzzleCorrect">Correct!</p>

        <p *ngIf="isIncorrect">Try Again!</p>

      </div>

    </div>
  </div>
</div>
