<div *ngIf="!currentUserService.currentUserData.soundwavePuzzleCorrect" class="clue">

  <p>Sound wave moving at <b>{{metersPerSecond}} meters per second</b> is sent  to ocean floor.</p>

  <p>It reflects off the ocean floor <b class="time">{{displaySeconds()}} seconds later</b>.</p>

</div>

<div class="notes">

  <p>Echo sounding is a method that scientists use to map the ocean floor. The technique uses sound waves bounced off the ocean bed. The faster the sound waves return, the smaller the water depths and the higher the elevation of the seafloor.</p>

</div>

<div *ngIf="currentUserService.currentUserData.soundwavePuzzleCorrect" class="clue">

  <p><b>Success!</b> You've mapped the ocean floor.</p>

</div>



<div class="depthChart">

  <div class="row">

    <div class="col">

      <div class="row blankOption"></div>

      <div *ngFor="let depth of depths" class="row depthAmount" (click)="selectDepth(index, depth)">

        <p>{{depth}}</p>

      </div>


    </div>

    <div *ngFor="let soundwaveAnswer of currentUserService.soundwaveAnswers; index as index" class="col colAnswer">

      <div class="row shipOption">
        <p>
        <i *ngIf="index === currentlyAnsweringIndex" class="fas fa-ship ship"></i>
        <i *ngIf="index !== currentlyAnsweringIndex" class="fas fa-water water"></i>
        </p>
      </div>

      <div *ngFor="let depth of depths" class="row depthOption" [ngClass]="depthOptionCompletedClass(index)" (click)="selectDepth(index, depth)">

        <p *ngIf="answers[index] === depth && soundwaveAnswer === depth"><i class="fas fa-check-circle right"></i></p>
        <p *ngIf="answers[index] === depth && soundwaveAnswer !== depth"><i class="fas fa-times-circle wrong"></i></p>

      </div>

    </div>

  </div>

</div>



<div class="whale">
  <p class="speechBubble"><a href="https://www.youtube.com/watch?v=JxS5E-kZc2s" target="_blank">Click here</a> to learn about ocean wildlife preservation.</p>
</div>
