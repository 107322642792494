<div class="row">
  <div class="col-7">
    <div class="binocularsImage"></div>
  </div>
  <div class="col-5">

    <div class="clue">

      <p><input [(ngModel)]="answer" /></p>

      <p><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

      <p *ngIf="currentUserService.currentUserData.binocularsPuzzleCorrect">Correct!</p>

      <p *ngIf="isIncorrect">Try Again!</p>

    </div>

  </div>
</div>
