import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-door',
  templateUrl: './puzzle-door.component.html',
  styleUrls: ['./puzzle-door.component.less']
})
export class PuzzleDoorComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void { }

  answerButtonPressed() {
    this.currentUserService.didAnswer('doorPuzzleCorrect', true)
    this.audioPlayerService.correctSound.play()
  }

}
