import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.less']
})
export class HintComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
  }

}
