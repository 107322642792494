import { Component, HostListener, OnInit } from '@angular/core'
import { AudioPlayerService } from './services/audio-player.service'
import { CurrentUserService } from './services/current-user.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {



  title = 'escaperoom'


  constructor(
    public audioPlayerService: AudioPlayerService,
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    this.audioPlayerService.init()
  }

  @HostListener('document:click', ['$event'])

  public documentClick(event: Event): void {
    this.audioPlayerService.clickSound.play();
  }

  toggleMusic() {
    this.audioPlayerService.toggleMusic()
  }

  cheat() {
    this.currentUserService.cheat()
  }

}
