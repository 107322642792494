import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-computer',
  templateUrl: './puzzle-computer.component.html',
  styleUrls: ['./puzzle-computer.component.less']
})
export class PuzzleComputerComponent implements OnInit {



  @ViewChild('videoPlayer') videoplayer: ElementRef;



  answer: string
  isIncorrect = false

  audioWasPlaying = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.computerPuzzleCorrect) {
      this.answer = this.currentUserService.computerPuzzleAnswer
    }
  }

  answerButtonPressed() {
    this.currentUserService.didAnswer('computerPuzzleCorrect', this.answer.toLowerCase() === this.currentUserService.computerPuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.computerPuzzleCorrect
    if (this.currentUserService.currentUserData.computerPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

  playVideo() {
    this.audioWasPlaying = this.audioPlayerService.backgroundSound.playing()
    this.audioPlayerService.backgroundSound.stop()
  }

  pauseVideo() {
    if (this.audioWasPlaying) {
      this.audioPlayerService.backgroundSound.play()
    }
  }

}
