import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-greenland',
  templateUrl: './puzzle-greenland.component.html',
  styleUrls: ['./puzzle-greenland.component.less']
})
export class PuzzleGreenlandComponent implements OnInit {



  answer: string
  isIncorrect = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.greenlandPuzzleCorrect) {
      this.answer = this.currentUserService.greenlandPuzzleAnswer
    }
  }

  answerButtonPressed() {
    const cleanAnswer = this.answer.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    this.currentUserService.didAnswer('greenlandPuzzleCorrect', cleanAnswer === this.currentUserService.greenlandPuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.greenlandPuzzleCorrect
    if (this.currentUserService.currentUserData.greenlandPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }
}
