import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { NgModule } from '@angular/core'

import { AuthGuard } from './guards/auth.guard'

import { AppRoutingModule } from './app-routing.module'

import { environment } from '../environments/environment'

import { AppComponent } from './app.component'
import { MainMenuComponent } from './components/subcomponents/main-menu/main-menu.component'
import { SignInComponent } from './components/subcomponents/sign-in/sign-in.component'
import { StartGameComponent } from './components/pages/start-game/start-game.component'
import { MapComponent } from './components/pages/map/map.component'
import { RoomComponent } from './components/pages/room/room.component'
import { PuzzleComponent } from './components/subcomponents/puzzle/puzzle.component'
import { GameComponent } from './components/pages/game/game.component'
import { AccountComponent } from './components/pages/account/account.component'
import { PuzzleDoorComponent } from './components/subcomponents/puzzle-door/puzzle-door.component'
import { PuzzleComputerComponent } from './components/subcomponents/puzzle-computer/puzzle-computer.component'
import { PuzzleMicroscopeComponent } from './components/subcomponents/puzzle-microscope/puzzle-microscope.component'
import { PuzzleMapComponent } from './components/subcomponents/puzzle-map/puzzle-map.component'
import { WelcomeComponent } from './components/pages/welcome/welcome.component'
import { PuzzleHudsonRiverComponent } from './components/subcomponents/puzzle-hudson-river/puzzle-hudson-river.component'
import { PuzzleResourceGuideComponent } from './components/subcomponents/puzzle-resource-guide/puzzle-resource-guide.component'
import { NewspaperComponent } from './components/pages/newspaper/newspaper.component'
import { PuzzleHeadlineComponent } from './components/subcomponents/puzzle-headline/puzzle-headline.component'
import { ShipComponent } from './components/pages/ship/ship.component'
import { PuzzleBinocularsComponent } from './components/subcomponents/puzzle-binoculars/puzzle-binoculars.component'
import { PuzzleRadioComponent } from './components/subcomponents/puzzle-radio/puzzle-radio.component'
import { PuzzleSoundwaveComponent } from './components/subcomponents/puzzle-soundwave/puzzle-soundwave.component'
import { PuzzleWheelComponent } from './components/subcomponents/puzzle-wheel/puzzle-wheel.component'
import { HintComponent } from './components/subcomponents/hint/hint.component'
import { ArcticComponent } from './components/pages/arctic/arctic.component'
import { PuzzleCodeComponent } from './components/subcomponents/puzzle-code/puzzle-code.component'
import { PuzzleGreenlandComponent } from './components/subcomponents/puzzle-greenland/puzzle-greenland.component'
import { PuzzleZappaComponent } from './components/subcomponents/puzzle-zappa/puzzle-zappa.component'
import { JungleComponent } from './components/pages/jungle/jungle.component'
import { CityComponent } from './components/pages/city/city.component'
import { PuzzleTreeRingsComponent } from './components/subcomponents/puzzle-tree-rings/puzzle-tree-rings.component'
import { PuzzleAdComponent } from './components/subcomponents/puzzle-ad/puzzle-ad.component'
import { PuzzleConveyorBeltComponent } from './components/subcomponents/puzzle-conveyor-belt/puzzle-conveyor-belt.component'
import { PuzzleSignComponent } from './components/subcomponents/puzzle-sign/puzzle-sign.component'
import { PuzzlePigpenKeyComponent } from './components/subcomponents/puzzle-pigpen-key/puzzle-pigpen-key.component'
import { PuzzlePlatesComponent } from './components/subcomponents/puzzle-plates/puzzle-plates.component'
import { WinnerComponent } from './components/pages/winner/winner.component'
import { InstructionsComponent } from './components/pages/instructions/instructions.component'
import { PuzzleMemoryComponent } from './components/subcomponents/puzzle-memory/puzzle-memory.component'
import { FeedbackComponent } from './components/pages/feedback/feedback.component';
import { InfoComponent } from './components/pages/info/info.component'



const ROUTES = [
  { path: '', component: StartGameComponent },
  { path: 'info', component: InfoComponent },
  { path: 'instructions', component: InstructionsComponent },
  { path: 'signin', component: AccountComponent },
  { path: 'game', component: GameComponent, canActivate: [AuthGuard],
    children: [
      { path: 'welcome', component: WelcomeComponent },
      { path: 'newspaper', component: NewspaperComponent },
      { path: 'map', component: MapComponent },
      { path: 'room', component: RoomComponent },
      { path: 'ship', component: ShipComponent },
      { path: 'arctic', component: ArcticComponent },
      { path: 'jungle', component: JungleComponent },
      { path: 'city', component: CityComponent },
      { path: 'winner', component: WinnerComponent },
      { path: 'feedback', component: FeedbackComponent }
    ]
  }
]

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    SignInComponent,
    StartGameComponent,
    MapComponent,
    RoomComponent,
    PuzzleComponent,
    GameComponent,
    AccountComponent,
    PuzzleDoorComponent,
    PuzzleComputerComponent,
    PuzzleMicroscopeComponent,
    PuzzleMapComponent,
    WelcomeComponent,
    PuzzleHudsonRiverComponent,
    PuzzleResourceGuideComponent,
    NewspaperComponent,
    PuzzleHeadlineComponent,
    ShipComponent,
    PuzzleBinocularsComponent,
    PuzzleRadioComponent,
    PuzzleSoundwaveComponent,
    PuzzleWheelComponent,
    HintComponent,
    ArcticComponent,
    PuzzleCodeComponent,
    PuzzleGreenlandComponent,
    PuzzleZappaComponent,
    JungleComponent,
    CityComponent,
    PuzzleTreeRingsComponent,
    PuzzleAdComponent,
    PuzzleConveyorBeltComponent,
    PuzzleSignComponent,
    PuzzlePigpenKeyComponent,
    PuzzlePlatesComponent,
    WinnerComponent,
    InstructionsComponent,
    PuzzleMemoryComponent,
    FeedbackComponent,
    InfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled', // Add options right here
    }),
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
