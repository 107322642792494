<div class="desktopImage"></div>

<div class="content">

  <div class="noteBubble">

    <div class="inner">

      <p>Welcome!</p>

      <p>I’m so pleased to welcome you aboard the small but dedicated staff at the newly relaunched ‘Columbia Geologist.’  Here at the CG, we are dedicated to illuminating the stories of the Earth. We strive to share important insights about our planet’s past, present and future by unearthing (excuse the pun!) new research and information from the brilliant scientists working in laboratories, on ships and in field stations all over the world. For your first assignment, I want you to find me one of these stories. I hear they’re having an Open House at Lamont Doherty Earth Observatory - maybe you can check it out and see what they have going on.</p>

      <p>Sincerely,</p>

      <p class="signature">DOROTHY LAMONT</p>

      <p class="signatureTitle">Editor in Chief</p>

    </div>

  </div>

  <p class="mapButton"><span [routerLink]="['/game/newspaper']" class="btn">Continue</span></p>

</div>
