<div class="board">
  <div
    class="row"
    *ngFor="let row of [0, 1, 2, 3]"
  >
    <div
      class="col"
      *ngFor="let col of [0, 1, 2, 3, 4]"
    >
      <div
        class="card"
        [ngClass]="cardStyle(row*5+col)"
        (click)="selectCard(row*5+col)"
      ></div>
    </div>
  </div>
</div>

<div class="result">

  <p *ngIf="noMatch">No match!</p>
  <p *ngIf="didFindMatch"><b>Match!</b></p>

  <p *ngIf="currentUserService.currentUserData.memoryPuzzleCorrect"><b>Winner!!!</b></p>

</div>
