<div *ngIf="!currentUserService.currentUserData.radioPuzzleCorrect" class="row">
  <div class="col-7">

    <div class="radioImage"></div>

    <p class="text">When Marie Tharp first arrived at the Lamont Geological Observatory in 1948, most believed the ocean floor consisted of flat plains of mud.  Tharp, the renowned oceanographer who mapped the ocean floor, liked calling them “saucers of mud”.  The Sonar readings she used to draw the details of the ocean floor, however, revealed an underwater world of seamounts and volcanic chains, trenches, mid-ocean ridges, and transform faults. Her drawings also showed the 40,000 miles of an underwater ridge that runs along the globe.  These observations led Tharp to believe that the continents move by spreading across the ocean floor – the theory of continental drift.  Mountain building or orogeny is a geological phenomenon explained by continental drift.  The work also helped prove the theory of plate tectonics, the idea that the continents move over time, and defined the types of plate tectonic boundaries – divergent, convergent and transform.  Further evidence of plate tectonics is in fossils, glacial activity and complementary coastlines.</p>

  </div>
  <div class="col-5">

    <div class="clue">

      <div class="questions">

        <p>Which of the following is NOT a kind of plate tectonic boundary?</p>

        <ol type = "A">
          <li>Conform</li>
          <li>Divergent</li>
          <li>Convergent</li>
        </ol>

        <p>What geological phenomenon is NOT explained by continental drift?</p>

        <ol type = "A">
          <li>Volcanic activity</li>
          <li>Mountain building (orogeny)</li>
          <li>Sinkholes</li>
        </ol>

        <p>What are two pieces of evidence for plate tectonics?</p>

        <ol type = "A">
          <li>Tides of the moon and fossils</li>
          <li>Glaciers and Complimentary Coastlines</li>
          <li>Ocean currents and the Bermuda Triangle</li>
        </ol>

      </div>

      <p>Find the right frequency.</p>

      <p><input [(ngModel)]="answer" /></p>

      <p><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

      <p *ngIf="isIncorrect">Try Again!</p>

    </div>

  </div>
</div>

<div *ngIf="currentUserService.currentUserData.radioPuzzleCorrect" class="row">
  <div class="col-5">
    <div class="tectonicsImage"></div>
  </div>
  <div class="col-7">

    <div class="clue">

      <p>Correct!</p>

      <audio
        controls
        (play)="playAudio()"
        (pause)="pauseAudio()"
      >
        <source src="/assets/audio/tectonics.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>

      <div class="info">

        <p><b>Hear Marie Tharp talking about her work.</b></p>

        <p>Marie Tharp’s pioneering contributions to seafloor mapping helped reveal Earth’s submarine landscape in unprecedented detail. Although her findings were initially dismissed by fellow researcher Bruce Heezen as "girl talk," her work ultimately played an essential role in the acceptance of the theory of plate tectonics.</p>

        <p>When Marie Tharp began working at the Lamont Geological Observatory (now the Lamont-Doherty Earth Observatory at Columbia University), very little was known about the structure of the seafloor; it was thought to be mostly flat and featureless. Tharp’s drawings revealed that the seafloor is instead covered in canyons, ridges, and mountains. Over time, her maps revealed the existence of the mid-ocean ridges, a series of mountain ranges that extend over 40,000 miles around the globe.</p>

      </div>

    </div>

  </div>
</div>
