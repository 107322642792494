<div class="fixedContainer">

  <div class="backgroundImage"></div>

  <span *ngIf="!currentUserService.currentUser" class="startButton" [routerLink]="['/signin']"></span>

  <span *ngIf="currentUserService.currentUser" class="startButton" [routerLink]="['/info']"></span>

</div>

<a *ngIf="currentUserService.currentUser" class="signOut" (click)="currentUserService.signOut()">Sign Out</a>
