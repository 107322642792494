<div *ngIf="currentUserService.currentUserData && currentUserService.currentUserData.firebaseUserId">

  <canvas #confettiCanvas class="confetti"></canvas>

  <app-main-menu></app-main-menu>

  <div class="objectCollection">

    <h4 class="items">Items</h4>

    <div *ngIf="currentUserService.currentUserData.hasWater && !currentUserService.selectedWater" class="gameObject gameObjectWater" (click)="selectItem(0)"></div>
    <div *ngIf="currentUserService.currentUserData.hasHeat && !currentUserService.selectedHeat" class="gameObject gameObjectHeat" (click)="selectItem(1)"></div>
    <div *ngIf="currentUserService.currentUserData.hasSalt && !currentUserService.selectedSalt" class="gameObject gameObjectSalt" (click)="selectItem(2)"></div>
    <div *ngIf="currentUserService.currentUserData.hasIce && !currentUserService.selectedIce" class="gameObject gameObjectIce" (click)="selectItem(3)"></div>

    <h4 class="plates">Plates</h4>

    <div *ngIf="currentUserService.hasPlate(0)" class="gameObject gameObjectPlate gameObjectPlate0"></div>
    <div *ngIf="currentUserService.hasPlate(1)" class="gameObject gameObjectPlate gameObjectPlate1"></div>
    <div *ngIf="currentUserService.hasPlate(2)" class="gameObject gameObjectPlate gameObjectPlate2"></div>
    <div *ngIf="currentUserService.hasPlate(3)" class="gameObject gameObjectPlate gameObjectPlate3"></div>
    <div *ngIf="currentUserService.hasPlate(4)" class="gameObject gameObjectPlate gameObjectPlate4"></div>

    <p class="btn combineButton" (click)="currentUserService.showPuzzle('plates')">Combine Plates</p>

  </div>

  <div class="fixedContainer">

    <router-outlet></router-outlet>

    <div class="shade" [ngClass]="shadeDisplayClass()"></div>

    <app-puzzle
      [ngClass]="puzzleDisplayClass()"
    ></app-puzzle>


  </div>

</div>
