import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-puzzle-conveyor-belt',
  templateUrl: './puzzle-conveyor-belt.component.html',
  styleUrls: ['./puzzle-conveyor-belt.component.less']
})
export class PuzzleConveyorBeltComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
  }

  selectStyle(which) {
    if (this.currentUserService.selectedItem >= 0 && this.currentUserService.selectedItem === which) { return 'selectActive' }

    if (which === 0 && this.currentUserService.selectedWater) { return 'selectWaterOn' }
    if (which === 1 && this.currentUserService.selectedHeat) { return 'selectHeatOn' }
    if (which === 2 && this.currentUserService.selectedSalt) { return 'selectSaltOn' }
    if (which === 3 && this.currentUserService.selectedIce) { return 'selectIceOn' }
  }

  clickItem(which) {
    this.currentUserService.selectedItem = which

    switch (which) {
      case 0: this.currentUserService.selectedWater = false; break
      case 1: this.currentUserService.selectedHeat = false; break
      case 2: this.currentUserService.selectedSalt = false; break
      case 3: this.currentUserService.selectedIce = false; break
    }
  }

  isComplete() {
    return this.currentUserService.selectedWater &&
      this.currentUserService.selectedHeat &&
      this.currentUserService.selectedSalt &&
      this.currentUserService.selectedIce
  }

}
