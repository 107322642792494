<div class="computerImage"></div>

<div class="clueWrapper">

  <video
    *ngIf="currentUserService.currentUserData.computerPuzzleCorrect"
    class="videoPlayer"
    controls poster="/assets/images/video_cover.jpg"
    (play)="playVideo()"
    (pause)="pauseVideo()"
  >
    <source src="/assets/videos/core_repository.mp4" type="video/mp4">
  </video>

  <div
    class="clue"
    *ngIf="!currentUserService.currentUserData.computerPuzzleCorrect"
  >

    <p>Layers on the ocean floor.</p>

    <p><input [(ngModel)]="answer" /></p>

    <p><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

    <p *ngIf="currentUserService.currentUserData.computerPuzzleCorrect">Correct!</p>

    <p *ngIf="isIncorrect">Try Again!</p>

  </div>

</div>
