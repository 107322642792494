<div class="desktopImage"></div>

<div *ngIf="!currentUserService.currentUserData.headlinePuzzleCorrect" class="newspaperUnsolvedImage"></div>

<div *ngIf="currentUserService.currentUserData.headlinePuzzleCorrect" class="newspaperSolvedImage"></div>

<a class="backButton" [routerLink]="['/game/welcome']"><i class="fas fa-chevron-left"></i></a>

<div class="pin pinHeadline" (click)="currentUserService.showPuzzle('headline')"><i class="fas fa-puzzle-piece"></i></div>

<div *ngIf="currentUserService.currentUserData.headlinePuzzleCorrect" class="pin pinPuzzle" (click)="currentUserService.showPuzzle('hudsonRiver')"><i class="fas fa-puzzle-piece"></i></div>

<div *ngIf="currentUserService.currentUserData.hudsonRiverPuzzleCorrect" class="pin pinMap" [routerLink]="['/game/map']"><i class="fas fa-globe-americas"></i></div>




<a class="hintButton" (click)="currentUserService.showHint('newspaper')">Hint</a>



<div class="hintShade" [ngClass]="currentUserService.hintShadeDisplayClass()"></div>

<app-hint
  [ngClass]="currentUserService.hintDisplayClass()"
></app-hint>
