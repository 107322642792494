<div class="row">
  <div class="col-9">
    <div class="coresImage">
      <div class="coresAnswer" (click)="answerButtonPressed()"></div>
    </div>
  </div>
  <div class="col-3">

    <div class="clue">

      <p *ngIf="currentUserService.currentUserData.doorPuzzleCorrect"><b>C2</b></p>

    </div>

  </div>
</div>
