import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {

  didSave = false

  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
  }

  async saveFeedback() {
    const observable = await this.currentUserService.postCurrentUser()
    observable.subscribe(user => {
      this.didSave = true
    })
  }

}
