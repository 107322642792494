<div class="feedbackImage"></div>

<div class="form">

  <form name="form" (ngSubmit)="f.form.valid && saveFeedback()" #f="ngForm">

    <textarea id="feedback" name="feedback" [(ngModel)]="currentUserService.currentUserData.feedback" required></textarea>

    <p><button type="submit" class="btn btn-primary">Save</button></p>

  </form>

  <hr />

  <p *ngIf="didSave">Saved! Thanks!</p>

</div>
