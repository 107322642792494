<div class="fixedContainer">

  <app-sign-in *ngIf="!currentUserService.currentUser"></app-sign-in>

  <div *ngIf="currentUserService.currentUser">

    <h1>Welcome</h1>

    <p>You're all set up!</p>

    <p class="startButton"><span [routerLink]="['/info']" class="btn">Start Game!</span></p>

  </div>

</div>
