import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.less']
})
export class InstructionsComponent implements OnInit {

  constructor(
    public currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
  }

}
