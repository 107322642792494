<app-puzzle-hudson-river [style.display]="currentUserService.visiblePuzzleType == 'hudsonRiver' ? 'block' : 'none'"></app-puzzle-hudson-river>

<app-puzzle-headline [style.display]="currentUserService.visiblePuzzleType == 'headline' ? 'block' : 'none'"></app-puzzle-headline>

<app-puzzle-resource-guide [style.display]="currentUserService.visiblePuzzleType == 'resourceGuide' ? 'block' : 'none'"></app-puzzle-resource-guide>

<ng-container *ngIf="reload">
  <app-puzzle-computer [style.display]="currentUserService.visiblePuzzleType == 'computer' ? 'block' : 'none'"></app-puzzle-computer>
</ng-container>

<app-puzzle-sign [style.display]="currentUserService.visiblePuzzleType == 'sign' ? 'block' : 'none'"></app-puzzle-sign>

<app-puzzle-door [style.display]="currentUserService.visiblePuzzleType == 'door' ? 'block' : 'none'"></app-puzzle-door>

<app-puzzle-map [style.display]="currentUserService.visiblePuzzleType == 'map' ? 'block' : 'none'"></app-puzzle-map>

<app-puzzle-microscope [style.display]="currentUserService.visiblePuzzleType == 'microscope' ? 'block' : 'none'"></app-puzzle-microscope>

<app-puzzle-binoculars [style.display]="currentUserService.visiblePuzzleType == 'binoculars' ? 'block' : 'none'"></app-puzzle-binoculars>

<app-puzzle-radio [style.display]="currentUserService.visiblePuzzleType == 'radio' ? 'block' : 'none'"></app-puzzle-radio>

<app-puzzle-soundwave [style.display]="currentUserService.visiblePuzzleType == 'soundwave' ? 'block' : 'none'"></app-puzzle-soundwave>

<app-puzzle-wheel [style.display]="currentUserService.visiblePuzzleType == 'wheel' ? 'block' : 'none'"></app-puzzle-wheel>

<app-puzzle-pigpen-key [style.display]="currentUserService.visiblePuzzleType == 'pigpenKey' ? 'block' : 'none'"></app-puzzle-pigpen-key>

<app-puzzle-code [style.display]="currentUserService.visiblePuzzleType == 'code' ? 'block' : 'none'"></app-puzzle-code>

<app-puzzle-greenland [style.display]="currentUserService.visiblePuzzleType == 'greenland' ? 'block' : 'none'"></app-puzzle-greenland>

<app-puzzle-zappa [style.display]="currentUserService.visiblePuzzleType == 'zappa' ? 'block' : 'none'"></app-puzzle-zappa>

<app-puzzle-tree-rings [style.display]="currentUserService.visiblePuzzleType == 'treeRings' ? 'block' : 'none'"></app-puzzle-tree-rings>

<app-puzzle-ad [style.display]="currentUserService.visiblePuzzleType == 'ad' ? 'block' : 'none'"></app-puzzle-ad>

<app-puzzle-conveyor-belt [style.display]="currentUserService.visiblePuzzleType == 'conveyorBelt' ? 'block' : 'none'"></app-puzzle-conveyor-belt>

<app-puzzle-memory [style.display]="currentUserService.visiblePuzzleType == 'memory' ? 'block' : 'none'"></app-puzzle-memory>

<app-puzzle-plates [style.display]="currentUserService.visiblePuzzleType == 'plates' ? 'block' : 'none'"></app-puzzle-plates>



<p class="closeButton"><span (click)="hidePuzzle()" class="btn">Close</span></p>
