import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-code',
  templateUrl: './puzzle-code.component.html',
  styleUrls: ['./puzzle-code.component.less']
})
export class PuzzleCodeComponent implements OnInit {



  answer: string
  isIncorrect = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.codePuzzleCorrect) {
      this.answer = this.currentUserService.codePuzzleAnswer
    }
  }

  answerButtonPressed() {
    this.currentUserService.didAnswer('codePuzzleCorrect', this.answer.toLowerCase() === this.currentUserService.codePuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.codePuzzleCorrect
    if (this.currentUserService.currentUserData.codePuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

}
