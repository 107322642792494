<div class="roomImage"></div>

<a class="backButton" [routerLink]="['/game/map']">Back to Map</a>

<div class="pin pinDoor" (click)="currentUserService.showPuzzle('door')"></div>
<div class="pin pinMap" (click)="currentUserService.showPuzzle('map')"></div>
<div class="pin pinMicroscope" (click)="currentUserService.showPuzzle('microscope')"></div>
<div class="pin pinComputer" (click)="currentUserService.showPuzzle('computer')"></div>
<div class="pin pinSign" (click)="currentUserService.showPuzzle('sign')"></div>

<div *ngIf="!currentUserService.currentUserData.hasSalt" class="pin pinSalt" (click)="currentUserService.collectSalt()"><i class="fas fa-cubes"></i></div>



<a class="hintButton" (click)="currentUserService.showHint('room')">Hint</a>



<a class="moreInfoButton" (click)="showMoreInfo()"><i class="fas fa-info"></i></a>

<div class="moreInfoShade" [ngClass]="shadeDisplayClass()"></div>

<div
  class="moreInfo"
  [ngClass]="moreInfoDisplayClass()"
>

  <p class="closeMoreInfoButton"><span (click)="hideMoreInfo()" class="btn">Close</span></p>

  <h1>The Core Lab</h1>

  <p>Like all libraries, it’s quiet, somewhat cavernous and filled with items brimming with information and waiting to be tapped.  The “pages” in this library, however, are made of mud.  The Lamont Doherty Core Repository contains thousands of long, thin plugs of mud extracted from the bottoms of every ocean, including the ice-covered Arctic Ocean, as well as from lakes, rivers, corals, even a cow pasture in Chilé.  They are stacked to the ceiling in neat rows on shelves made of wire racks.  Scientists from all over the world come to this library, just as they do most libraries, in the pursuit of knowledge about the Earth.   They borrow samples of the cores.  In them they can read stories of climate changes, ocean currents, marine life, volcanic eruptions, deep-sea oil reservoirs – even sunken submarines!</p>

  <p>The earliest cores in the Core Repository collection were taken in 1947, on the R/V ATLANTIS, by Maurice “Doc” Ewing, Lamont’s founder and first director. Doc Ewing knew that if Lamont gathered as many cores as possible, from as many places as possible, patterns would emerge that would reveal the geological history of our planet. Thus, the dictum came down: “A core a day keeps ‘Doc’ happy."  From the early 1950’s to the late 1980’s Lamont’s two ships, the R/V VEMA and the R/V ROBERT D. CONRAD, sailed the world’s oceans each logging over 1 million miles.  Each of the tremendous number of cores arriving at the Repository were carefully archived.  The Repository quickly becomes the world’s largest collection of deep-sea cores. The 50-year database of the collection contains information on the geographic location of each collection site, core length, mineralogy and paleontology, lithology, and structure, and recently, the full text of megascopic descriptions.</p>

  <p>Maurice Ewing’s foresight in collecting “A core-a-day” created a scientific legacy that now constitutes one of Earth Science’s foremost oceanographic and geologic resources.</p>

  <p><a href="https://www.ldeo.columbia.edu/core-repository">https://www.ldeo.columbia.edu/core-repository
  </a></p>

</div>



<div class="hintShade" [ngClass]="currentUserService.hintShadeDisplayClass()"></div>

<app-hint
  [ngClass]="currentUserService.hintDisplayClass()"
></app-hint>
