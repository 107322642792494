import { Serializable } from './serializable'

export class User extends Serializable {
  id: number
  firebaseUserId: string

  teamName: string
  teamSize: number

  hasWater: boolean
  hasHeat: boolean
  hasSalt: boolean
  hasIce: boolean

  headlinePuzzleCorrect: boolean
  hudsonRiverPuzzleCorrect: boolean
  didViewMail: boolean

  doorPuzzleCorrect: boolean
  computerPuzzleCorrect: boolean
  mapPuzzleCorrect: boolean

  binocularsPuzzleCorrect: boolean
  radioPuzzleCorrect: boolean
  soundwavePuzzleCorrect: boolean
  wheelPuzzleCorrect: boolean

  codePuzzleCorrect: boolean
  greenlandPuzzleCorrect: boolean

  treeRingsPuzzleCorrect: boolean

  memoryPuzzleCorrect: boolean

  feedback: string

  constructor(data: any) {
    super(data)
  }

}
