<p class="closeButton"><span (click)="currentUserService.hideHint()" class="btn"><i class="fas fa-times"></i></span></p>

<div *ngIf="currentUserService.hintRoom === 'newspaper'">

  <p>Speak To The Earth: A Caesar wheel is a substitution cipher. Inside ring contains the encryptedd message. The outside ring holds the solution.</p>

  <p>River Article: The Hudson River is one of these. It says so in the article.</p>


</div>

<div *ngIf="currentUserService.hintRoom === 'room'">

  <p>Computer password puzzle: Can you find any information about the ocean floor in any of the other hotspots?</p>

  <p>Microscope: Be sure to notice ALL the information on this page</p>

  <p>Door: These are cores. Each one is labeled. Do any of the labels look familiar?</p>

  <p>Laboratory Sign: Sometimes you are just collecting information that you can use somewhere else.</p>

   <p>Map: The dots in the ocean on this map refer to core sample sites. Make sure you keep an eye on whether this map changes. You might need to look at it more than once.</p>

</div>

<div *ngIf="currentUserService.hintRoom === 'ship'">

  <p>Echosound puzzle: Click the correct point on the graph to map the ocean floor. The first coordinate is 1500m.</p>

  <p>Binoculars: Every ship has a name. This is pigpen cipher. There is a key to the code hidden on the ship’s bridge. When you find it, notice the gridlines around the letters. Do they look like the code on the photo of the ship?</p>

  <p>Ship’s wheel: Fractions can be expressed in many ways. Also – this wheel can move.</p>

  <p>Radio Frequency puzzle: Make sure you read the questions carefully – and then convert the letter answers to numbers.</p>

</div>

<div *ngIf="currentUserService.hintRoom === 'arctic'">

  <p>Greenland ice question: Sometimes you don’t have all the information you need to do your calculation. You might need to go forwards to gather more info (like the volume of an Olympic swimming pool.) Don’t be discouraged!</p>

  <p>Crate combination: By a process of elimination, find the three digit code to unlock the box.</p>


</div>

<div *ngIf="currentUserService.hintRoom === 'jungle'">

  <p>Tree’s age: The two outer bands are the bark of the tree. Start in the ‘present day’ (1979) and count inwards towards the center to find the tree’s age.</p>

</div>

<div *ngIf="currentUserService.hintRoom === 'city'">

  <p>Hotel: That is a very big pool! No wonder they want to tell you its dimensions. Its volume is 3750 m3!</p>

  <p>Message in a bottle: Can you complete the sentences by placing the correct collectible (salt, fresh water, thermometer, Greenland) in the right places?</p>

</div>
