<div class="shipImage"></div>

<a class="backButton" [routerLink]="['/game/map']">Back to Map</a>

<div class="pin pinBinoculars" (click)="currentUserService.showPuzzle('binoculars')"></div>
<div class="pin pinRadio" (click)="currentUserService.showPuzzle('radio')"></div>
<div class="pin pinSoundwave" (click)="currentUserService.showPuzzle('soundwave')"></div>
<div class="pin pinWheel" (click)="currentUserService.showPuzzle('wheel')"></div>
<div class="pin pinPigpenKey" (click)="currentUserService.showPuzzle('pigpenKey')"></div>



<a class="hintButton" (click)="currentUserService.showHint('ship')">Hint</a>



<a class="moreInfoButton" (click)="showMoreInfo()"><i class="fas fa-info"></i></a>

<div class="moreInfoShade" [ngClass]="shadeDisplayClass()"></div>

<div
  class="moreInfo"
  [ngClass]="moreInfoDisplayClass()"
>

  <p class="closeMoreInfoButton"><span (click)="hideMoreInfo()" class="btn">Close</span></p>

  <h1>LDEO Ship History</h1>

  <p>Ships and seas have been a part of Lamont since the days of the <i>Vema</i>, the <i>Conrad</i>, the <i>Eltanin</i> and, of course, the <i>Maurice Ewing</i> – which, collectively, have enabled the Observatory to conduct explorations of our planet’s oceans and seafloor for nearly 70 years.</p>

  <p>The <i>Vema</i>, an iron-hull racing yacht, began as a 202-foot, three-masted luxury schooner that sported a huge, determined-looking eagle figurehead representing Lamont’s indomitable spirit. By the time she was retired, this now-legendary research vessel had collected vital data along 1,225,000 nautical miles of track—a world record.</p>

  <p>In 1962, the <i>Conrad</i> started ambitious, near-continuous research missions. Deep-sea seismic reflection equipment, developed at Lamont, allowed worldwide mapping of ocean floor sediments while the ship also collected magnetic, gravity and heat-flow data. This vast flow of information is vital to our present perception of oceans as young features, originating at the mid-ocean ridges by a process known as seafloor spreading.</p>

  <p>The <i>USNS Eltanin</i>, an ice-breaking cargo ship that was refitted and reclassified as an Oceanographic Research Vessel, was one of the world’s first Antarctic research ships.</p>

  <p>The <i>Maurice Ewing</i> collected data over half a million miles of track. Cruising at 11 knots and able to endure up to 60 days at sea, the Ewing plied the seas from Atlantic to Pacific, and from the Arctic to Antarctica.</p>

  <p>Lamont’s Office of Marine Operations currently owns and operates the <i>R/V Marcus G. Langseth</i>, a national seismic research facility for the US academic community. The <i>Langseth</i>'s unique seismic capability allows it to provide both 2D and 3D maps of the earth's structure miles below the seafloor. Scientists and researchers from all over the world participate in research programs aboard the <i>Langseth</i>.</p>

  <p><a href="https://www.ldeo.columbia.edu/research/office-of-marine-operations" target="_blank">https://www.ldeo.columbia.edu/research/office-of-marine-operations</a></p>

</div>



<div class="hintShade" [ngClass]="currentUserService.hintShadeDisplayClass()"></div>

<app-hint
  [ngClass]="currentUserService.hintDisplayClass()"
></app-hint>
