import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-puzzle-plates',
  templateUrl: './puzzle-plates.component.html',
  styleUrls: ['./puzzle-plates.component.less']
})
export class PuzzlePlatesComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
  }

  placePlate(index) {
    this.currentUserService.plateIsInPlace[index] = true
  }

}
