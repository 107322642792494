import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-winner',
  templateUrl: './winner.component.html',
  styleUrls: ['./winner.component.less']
})
export class WinnerComponent implements OnInit {

  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.audioPlayerService.organSound.play()
      this.currentUserService.showConfetti(null)
    }, 1000)
  }

}
