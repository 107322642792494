import { Component, OnInit } from '@angular/core';
import {CurrentUserService} from '../../../services/current-user.service'
import {AudioPlayerService} from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-radio',
  templateUrl: './puzzle-radio.component.html',
  styleUrls: ['./puzzle-radio.component.less']
})
export class PuzzleRadioComponent implements OnInit {



  answer: string
  isIncorrect = false

  audioWasPlaying = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.radioPuzzleCorrect) {
      this.answer = this.currentUserService.radioPuzzleAnswer
    }
  }

  answerButtonPressed() {
    this.currentUserService.didAnswer('radioPuzzleCorrect', this.answer.toLowerCase() === this.currentUserService.radioPuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.radioPuzzleCorrect
    if (this.currentUserService.currentUserData.radioPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

  playAudio() {
    this.audioWasPlaying = this.audioPlayerService.backgroundSound.playing()
    this.audioPlayerService.backgroundSound.stop()
  }

  pauseAudio() {
    if (this.audioWasPlaying) {
      this.audioPlayerService.backgroundSound.play()
    }
  }

}
