import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-binoculars',
  templateUrl: './puzzle-binoculars.component.html',
  styleUrls: ['./puzzle-binoculars.component.less']
})
export class PuzzleBinocularsComponent implements OnInit {



  answer: string
  isIncorrect = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.binocularsPuzzleCorrect) {
      this.answer = this.currentUserService.binocularsPuzzleAnswer
    }
  }

  answerButtonPressed() {
    this.currentUserService.didAnswer('binocularsPuzzleCorrect', this.answer.toLowerCase().replace(/\s/g, '') === this.currentUserService.binocularsPuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.binocularsPuzzleCorrect
    if (this.currentUserService.currentUserData.binocularsPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

}
