<div class="cityImage"></div>

<a class="backButton" [routerLink]="['/game/map']">Back to Map</a>

<div class="pin pinAd" (click)="currentUserService.showPuzzle('ad')"></div>
<div class="pinImage pinBottle" (click)="currentUserService.showPuzzle('conveyorBelt')"></div>
<div class="pin pinSatellite" (click)="currentUserService.showPuzzle('memory')"></div>



<a class="hintButton" (click)="currentUserService.showHint('city')">Hint</a>



<a class="moreInfoButton" (click)="showMoreInfo()"><i class="fas fa-info"></i></a>

<div class="moreInfoShade" [ngClass]="shadeDisplayClass()"></div>

<div
  class="moreInfo"
  [ngClass]="moreInfoDisplayClass()"
>

  <p class="closeMoreInfoButton"><span (click)="hideMoreInfo()" class="btn">Close</span></p>

  <h1>Coastal Cities</h1>

  <p>Coming soon!</p>

</div>



<div class="hintShade" [ngClass]="currentUserService.hintShadeDisplayClass()"></div>

<app-hint
  [ngClass]="currentUserService.hintDisplayClass()"
></app-hint>
