import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import {Router} from '@angular/router'

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.less']
})
export class MainMenuComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  reset() {
    this.currentUserService.resetGame()
    this.router.navigate(['/'])
  }

}
