export class Serializable {

  constructor(private json?: any) {
    for (const propName in json) {
      if (json.hasOwnProperty(propName)) {
        this[propName] = json[propName]
      }
    }
  }

}
