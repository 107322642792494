<div class="arcticImage"></div>

<a class="backButton" [routerLink]="['/game/map']">Back to Map</a>

<div class="pin pinCode" (click)="currentUserService.showPuzzle('code')"></div>
<div class="pin pinGreenland" (click)="currentUserService.showPuzzle('greenland')"></div>
<div class="pin pinZappa" (click)="currentUserService.showPuzzle('zappa')"></div>

<div *ngIf="!currentUserService.currentUserData.hasWater" class="pin pinWater" (click)="currentUserService.collectWater()"></div>



<a class="hintButton" (click)="currentUserService.showHint('arctic')">Hint</a>



<a class="moreInfoButton" (click)="showMoreInfo()"><i class="fas fa-info"></i></a>

<div class="moreInfoShade" [ngClass]="shadeDisplayClass()"></div>

<div
  class="moreInfo"
  [ngClass]="moreInfoDisplayClass()"
>

  <p class="closeMoreInfoButton"><span (click)="hideMoreInfo()" class="btn">Close</span></p>

  <h1>Polar Station</h1>

  <p>Earth’s polar areas are warming faster than any other place on the planet, and the consequences for all living organisms are profound. Researchers say that under a best-case scenario, with society greatly reducing emissions, melting in Greenland in the 21st century will be slightly higher than that of any other century of the past 12,000 years. Lamont-Doherty geochemists Nicolás Young and Joerg Schaefer were part of a large team that helped to make this discovery by mapping out the history of the ice sheet.</p>

  <p>Andy Juhl and Craig Aumack, scientists in Lamont’s division of Biology and Paleo Environment, are researching how our fast-warming climate and the resulting loss of sea ice affect the entire marine food web in the Arctic. This knowledge will be vital for policymakers as well as local residents.</p>

  <p>Leading polar researcher and Lamont professor Robin Bell and her team are using <a href="http://www.ldeo.columbia.edu/res/pi/icepod/" target="_blank">IcePod</a> — an instrument equipped with ice-penetrating radar and other sensors — as well as gravity data to explore Antarctica’s <a href="http://www.ldeo.columbia.edu/res/pi/rosetta/" target="_blank">Ross Ice Shelf</a>, a floating piece of ice the size of France that covers the least known piece of ocean floor on our planet.</p>

  <p>Since the early days at Lamont’s Arctic Station Alpha to ongoing research at Antarctica’s US McMurdo Research Station, Lamont scientists travel the world and with each voyage their understanding of Earth improves.</p>

  <p>“We won’t know where the most interesting places are until we have seen them.” — Maurice ‘Doc’ Ewing</p>

  <p><a href="https://www.ldeo.columbia.edu/news-events/greenland-track-lose-ice-faster-any-century-over-last-12000-years" target="_blank">https://www.ldeo.columbia.edu/news-events/greenland-track-lose-ice-faster-any-century-over-last-12000-years</a></p>

  <p><a href="https://www.ldeo.columbia.edu/research/blogs/arctic-sea-ice-ecology" target="_blank">https://www.ldeo.columbia.edu/research/blogs/arctic-sea-ice-ecology</a></p>

  <p><a href="http://www.ldeo.columbia.edu/res/pi/icepod/" target="_blank">http://www.ldeo.columbia.edu/res/pi/icepod/</a></p>

</div>



<div class="hintShade" [ngClass]="currentUserService.hintShadeDisplayClass()"></div>

<app-hint
  [ngClass]="currentUserService.hintDisplayClass()"
></app-hint>
