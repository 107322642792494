<div class="jungleImage"></div>

<a class="backButton" [routerLink]="['/game/map']">Back to Map</a>

<div class="pin pinTreeRings" (click)="currentUserService.showPuzzle('treeRings')"></div>



<a class="hintButton" (click)="currentUserService.showHint('jungle')">Hint</a>



<a class="moreInfoButton" (click)="showMoreInfo()"><i class="fas fa-info"></i></a>

<div class="moreInfoShade" [ngClass]="shadeDisplayClass()"></div>

<div
  class="moreInfo"
  [ngClass]="moreInfoDisplayClass()"
>

  <p class="closeMoreInfoButton"><span (click)="hideMoreInfo()" class="btn">Close</span></p>

  <h1>The Tree Ring Lab</h1>

  <p>Walking through the Tree Ring Lab at Lamont-Doherty Earth Observatory, you will find pencil-thin wood cores under microscopes and growth rings measured to precision. These samples come from trees all over the world, and together they hold thousands of years of climate history – such as when major droughts set in, when the planet cooled and when it warmed, even when earthquakes rattled the landscape and volcanoes erupted.</p>

  <p>Dendrochronology, or tree-ring dating, is the scientific method of dating past events using growth rings in tree trunks. Tree rings provide data for dendroclimatology, or the science of determining past climates from trees.</p>

  <p>When climate scientists Ed Cook and Gordon Jacoby started the Lamont Tree Ring Lab over 40 years ago, tree ring research was young. Among the first in the world, the lab — part of Lamont’s Division of Biology and Paleo Environment — expanded dendrochronology’s capabilities over the years and is a global leader in research, training and technology.</p>

  <p>“Silent though they are, the trees, it turns out, can speak volumes,” Jacoby wrote in 1999. “The size, density, anatomy and chemistry of each ring reflect the environmental conditions in the year in which it grew. So like ancient scribes, long-lived trees can sensitively record the environmental history of a given place and time.”</p>

  <p><a href="https://www.ldeo.columbia.edu/research/biology-paleo-environment/tree-ring-research-laboratory" target="_blank">https://www.ldeo.columbia.edu/research/biology-paleo-environment/tree-ring-research-laboratory</a></p>

</div>



<div class="hintShade" [ngClass]="currentUserService.hintShadeDisplayClass()"></div>

<app-hint
  [ngClass]="currentUserService.hintDisplayClass()"
></app-hint>
