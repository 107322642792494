import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-wheel',
  templateUrl: './puzzle-wheel.component.html',
  styleUrls: ['./puzzle-wheel.component.less']
})
export class PuzzleWheelComponent implements OnInit {



  @ViewChild('wheelImage') wheelImageElement: ElementRef



  answer: string
  isIncorrect = false

  isTurning = false

  startingAngleDegrees = 0
  currentAngleDegrees = 0

  rotationDegrees = 0



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.wheelPuzzleCorrect) {
      this.answer = this.currentUserService.wheelPuzzleAnswer
    }
  }

  answerButtonPressed() {
    this.currentUserService.didAnswer('wheelPuzzleCorrect', this.answer.toLowerCase() === this.currentUserService.wheelPuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.wheelPuzzleCorrect
    if (this.currentUserService.currentUserData.wheelPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

  startTurnWheel(event) {
    this.isTurning = true
    const degrees = this.degreesFromEvent(event)
    this.startingAngleDegrees = degrees - this.currentAngleDegrees
    console.log('---')
    console.log('this.startingAngleDegrees: ' + this.startingAngleDegrees)
  }

  @HostListener('mousemove', ['$event'])

  onMouseMove(event) {
    if (this.isTurning) {
      const degrees = this.degreesFromEvent(event)
      const lastAngleDegrees = this.currentAngleDegrees
      this.currentAngleDegrees = degrees - this.startingAngleDegrees

      let rawRotationDegrees = this.currentAngleDegrees - lastAngleDegrees
      if (rawRotationDegrees > 180) { rawRotationDegrees -= 360 } else if (rawRotationDegrees < -180) { rawRotationDegrees += 360 }

      this.rotationDegrees += rawRotationDegrees

      this.wheelImageElement.nativeElement.style.transform = 'rotate(' + this.rotationDegrees + 'deg)'

      const displayRotationCount = this.rotationDegrees / 360
      const displayRotationCountRounded = Math.round(displayRotationCount * 10) / 10

      this.answer = `${ displayRotationCountRounded }`
    }
  }

  mod(n, m) {
    return ((n % m) + m) % m;
  }

  degreesFromEvent(event) {
    const boundingRect = this.wheelImageElement.nativeElement.getBoundingClientRect()
    const wheelCenterX = boundingRect.x + boundingRect.width * 0.5
    const wheelCenterY = boundingRect.y + boundingRect.height * 0.5
    const radians = Math.atan2(event.clientX - wheelCenterX, event.clientY - wheelCenterY)
    const degrees = Math.round((radians * (180 / Math.PI) * -1) + 100)
    return degrees
  }

  @HostListener('mouseup', ['$event'])

  public onMouseUp(event) {
    this.isTurning = false
  }

}
