import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-hudson-river',
  templateUrl: './puzzle-hudson-river.component.html',
  styleUrls: ['./puzzle-hudson-river.component.less']
})
export class PuzzleHudsonRiverComponent implements OnInit {



  answer: string
  isIncorrect = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.hudsonRiverPuzzleCorrect) {
      this.answer = this.currentUserService.hudsonRiverPuzzleAnswer
    }
  }

  answerButtonPressed() {
    const isCorrect = this.answer.toLowerCase() === this.currentUserService.hudsonRiverPuzzleAnswer
    this.currentUserService.didAnswer('hudsonRiverPuzzleCorrect', isCorrect)
    this.isIncorrect = !this.currentUserService.currentUserData.hudsonRiverPuzzleCorrect
    if (this.currentUserService.currentUserData.hudsonRiverPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

}
