import { Component, OnInit, ViewChild } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.less']
})
export class GameComponent implements OnInit {



  @ViewChild('confettiCanvas') confettiCanvas;



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {}

  shadeDisplayClass() {
    if (!this.currentUserService.didShowPuzzle) { return '' }
    return this.currentUserService.puzzleIsVisible ? 'shadeIsVisible' : 'shadeIsHidden'
  }

  puzzleDisplayClass() {
    if (!this.currentUserService.didShowPuzzle) { return '' }
    return this.currentUserService.puzzleIsVisible ? 'puzzleIsVisible' : 'puzzleIsHidden'
  }

  selectItem(which) {
    if (which === this.currentUserService.selectedItem) {
      this.currentUserService.selectedItem = -1
      switch (which) {
        case 0: this.currentUserService.selectedWater = true; break
        case 1: this.currentUserService.selectedHeat = true; break
        case 2: this.currentUserService.selectedSalt = true; break
        case 3: this.currentUserService.selectedIce = true; break
      }
    }
  }

}
