<div class="row">
  <div class="col-7 article">

    <img class="header" src="/assets/images/newspaper_header.jpg"/>

    <img class="photo" src="/assets/images/hudson_river.jpg" width="250" />

    <p>The Hudson River flows 315 miles from <i>Lake Tear <span class="red">O</span>f The Clouds</i> in the Adirondack Mountains to the Battery in New York City. A <span class="red">j</span>agged network of streams, lakes, and other rivers feeds into the Hudson River. Its watershed covers nearly 13,400 square miles, an area equal in size to Massachusetts and Connecticut combined.</p>

    <p>A tidal river, the Hudson is influenced by ocean tides all the way to Troy, which is north of Albany. Thus, from Troy south to the Battery, the Hudson <span class="red">f</span>&zwnj;lows both ways, following the tides. Additionally, the river contains varying amounts of salt water as far north as Newburgh.</p>

    <p>Geologists refer to the Hudson <span class="red">R</span>iver as a fjord, similar to the fjords of Norway. The river is deepest —216 feet —  in the gorge called World's End near Garrison. World's End received its name from ship captains who tried to navigate this <span class="red">d</span>angerous Hudson Highlands portion of the river. "The whirlwinds, changing currents, and unpredictable weather so treacherous for sailors then and now stem from the tidal action and natural features of the land as the Hudson forces its way through the gorge," explains Frances Dunwell in her book <i>Hudson River Highlands</i>.</p>

  </div>
  <div class="col-1"></div>
  <div class="col-4">

    <div class="clue">

      <p><input [(ngModel)]="answer" /></p>

      <p><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

      <p *ngIf="currentUserService.currentUserData.hudsonRiverPuzzleCorrect">Correct! <b>You have mail!</b></p>

      <p *ngIf="isIncorrect">Try Again!</p>

    </div>

  </div>
</div>
