  import { Component, OnInit } from '@angular/core'
  import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-puzzle',
  templateUrl: './puzzle.component.html',
  styleUrls: ['./puzzle.component.less']
})
export class PuzzleComponent implements OnInit {



  reload = true



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
  }

  hidePuzzle() {
    this.reloadPuzzle()
    this.currentUserService.hidePuzzle()
  }

  private reloadPuzzle() {
    setTimeout(() => this.reload = false)
    setTimeout(() => this.reload = true)
  }

}
