<div class="plateWrapper">
  <div *ngIf="currentUserService.hasPlate(0) && currentUserService.plateIsInPlace[0]" class="plate plate0"></div>
  <div *ngIf="currentUserService.hasPlate(1) && currentUserService.plateIsInPlace[1]" class="plate plate1"></div>
  <div *ngIf="currentUserService.hasPlate(2) && currentUserService.plateIsInPlace[2]" class="plate plate2"></div>
  <div *ngIf="currentUserService.hasPlate(3) && currentUserService.plateIsInPlace[3]" class="plate plate3"></div>
  <div *ngIf="currentUserService.hasPlate(4) && currentUserService.plateIsInPlace[4]" class="plate plate4"></div>
</div>

<div *ngIf="!currentUserService.allPlatesInPlace()">

  <h3>Click to Assemble</h3>

  <div *ngIf="currentUserService.hasPlate(0) && !currentUserService.plateIsInPlace[0]" (click)="placePlate(0)" class="unplacedPlate unplacedPlate0"></div>
  <div *ngIf="currentUserService.hasPlate(1) && !currentUserService.plateIsInPlace[1]" (click)="placePlate(1)" class="unplacedPlate unplacedPlate1"></div>
  <div *ngIf="currentUserService.hasPlate(2) && !currentUserService.plateIsInPlace[2]" (click)="placePlate(2)" class="unplacedPlate unplacedPlate2"></div>
  <div *ngIf="currentUserService.hasPlate(3) && !currentUserService.plateIsInPlace[3]" (click)="placePlate(3)" class="unplacedPlate unplacedPlate3"></div>
  <div *ngIf="currentUserService.hasPlate(4) && !currentUserService.plateIsInPlace[4]" (click)="placePlate(4)" class="unplacedPlate unplacedPlate4"></div>

</div>

<div *ngIf="currentUserService.allPlatesInPlace()">

  <h3>Success!</h3>

  <p class="continueButton"><span (click)="currentUserService.hidePuzzle()" [routerLink]="['/game/winner']" class="btn">Continue</span></p>

</div>
