import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-tree-rings',
  templateUrl: './puzzle-tree-rings.component.html',
  styleUrls: ['./puzzle-tree-rings.component.less']
})
export class PuzzleTreeRingsComponent implements OnInit {



  answer: string
  isIncorrect = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.treeRingsPuzzleCorrect) {
      this.answer = this.currentUserService.treeRingsPuzzleAnswer
    }
  }

  answerButtonPressed() {
    const cleanAnswer = this.answer.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    this.currentUserService.didAnswer('treeRingsPuzzleCorrect',  cleanAnswer === this.currentUserService.treeRingsPuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.treeRingsPuzzleCorrect
    if (this.currentUserService.currentUserData.treeRingsPuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

}
