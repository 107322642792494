import { HostListener, Injectable } from '@angular/core'
import { Howl } from 'howler'

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {



  clickSound: Howl
  backgroundSound: Howl
  correctSound: Howl
  sonarSound: Howl
  organSound: Howl



  init() {

    this.clickSound = new Howl({
      src: ['/assets/audio/click.wav']
    })

    this.correctSound = new Howl({
      src: ['/assets/audio/blip.mp3']
    })

    this.backgroundSound = new Howl({
      src: ['/assets/audio/guitar.mp3'],
      autoplay: false,
      loop: true,
      volume: 0.25
    })

    this.sonarSound = new Howl({
      src: ['/assets/audio/sonar.wav']
    })

    this.organSound = new Howl({
      src: ['/assets/audio/organ.m4a']
    })

  }

  toggleMusic() {
    if (this.backgroundSound.playing()) {
      this.backgroundSound.stop()
    } else {
      this.backgroundSound.play()
    }
  }

}
