<div class="row">
  <div class="col-6">
    <div class="greenlandImage"></div>
  </div>
  <div class="col-6">

    <div class="clue">

      <p class="intro">Satelite images show that Greenland’s ice sheet lost 200 gigatons of ice per year from 2003-2019 (16 years), mostly from coastal glaciers, due to warmer air and ocean temperatures. One glacier alone lost ~22 gt/yr.</p>

      <p class="intro">1 gigaton would fill 400,000 Olympic swimming pools.</p>

      <p>What is the volume of ice that has been lost since 2003 (in m<sup>3</sup>)?</p>

      <p><input [(ngModel)]="answer" /></p>

      <p><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

      <p *ngIf="currentUserService.currentUserData.greenlandPuzzleCorrect">Correct!</p>

      <div *ngIf="currentUserService.currentUserData.greenlandPuzzleCorrect && !currentUserService.currentUserData.hasIce" class="greenlandGameObject" (click)="currentUserService.collectIce()"></div>

      <p *ngIf="isIncorrect">Try Again!</p>

    </div>

  </div>
</div>
