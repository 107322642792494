import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../../services/current-user.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less']
})
export class InfoComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async saveInfo() {
    const observable = await this.currentUserService.postCurrentUser()
    observable.subscribe(user => {
      this.router.navigate(['/instructions'])
    })
  }

}
