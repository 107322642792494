<div *ngIf="!isComplete()">

  <div class="beltImage"></div>

  <div class="selectButton selectWater selectEmpty" [ngClass]="selectStyle(0)" (click)="clickItem(0)">+</div>
  <div class="selectButton selectHeat selectEmpty" [ngClass]="selectStyle(1)" (click)="clickItem(1)">+</div>
  <div class="selectButton selectSalt selectEmpty" [ngClass]="selectStyle(2)" (click)="clickItem(2)">+</div>
  <div class="selectButton selectIce selectEmpty" [ngClass]="selectStyle(3)" (click)="clickItem(3)">+</div>

</div>

<div *ngIf="isComplete()">

  <iframe class="video" width="560" height="315" src="https://www.youtube.com/embed/3niR_-Kv4SM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

</div>
