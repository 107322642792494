import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-soundwave',
  templateUrl: './puzzle-soundwave.component.html',
  styleUrls: ['./puzzle-soundwave.component.less']
})
export class PuzzleSoundwaveComponent implements OnInit {


  answers = []

  metersPerSecond = 1500

  depths = [250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500]

  currentlyAnsweringIndex = 0



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
  }

  selectDepth(index, depth) {

    this.answers[index] = depth

    if (depth === this.currentUserService.soundwaveAnswers[this.currentlyAnsweringIndex]) {
      this.currentlyAnsweringIndex++
      this.audioPlayerService.sonarSound.play()
    }

    if (this.currentlyAnsweringIndex >= this.depths.length) {
      this.currentUserService.didAnswer('soundwavePuzzleCorrect', true)
    }

  }

  displaySeconds() {
    const seconds = this.currentUserService.soundwaveAnswers[this.currentlyAnsweringIndex] / this.metersPerSecond
    return Math.round(seconds * 100) / 100
  }

  depthOptionCompletedClass(index) {
    if (this.currentlyAnsweringIndex < index + 1) {
      return 'depthOptionComplete'
    }
  }

}
