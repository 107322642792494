import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-map',
  templateUrl: './puzzle-map.component.html',
  styleUrls: ['./puzzle-map.component.less']
})
export class PuzzleMapComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void { }

  answerButtonPressed() {
    this.currentUserService.didAnswer('mapPuzzleCorrect', true)
    this.audioPlayerService.correctSound.play()
  }

}
