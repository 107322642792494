import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'
import { AudioPlayerService } from '../../../services/audio-player.service'

@Component({
  selector: 'app-puzzle-headline',
  templateUrl: './puzzle-headline.component.html',
  styleUrls: ['./puzzle-headline.component.less']
})
export class PuzzleHeadlineComponent implements OnInit {



  answer: string
  isIncorrect = false



  constructor(
    public currentUserService: CurrentUserService,
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit(): void {
    if (this.currentUserService.currentUserData.headlinePuzzleCorrect) {
      this.answer = this.currentUserService.headlinePuzzleAnswer
    }
  }

  answerButtonPressed() {
    this.currentUserService.didAnswer('headlinePuzzleCorrect', this.answer.toLowerCase() === this.currentUserService.headlinePuzzleAnswer)
    this.isIncorrect = !this.currentUserService.currentUserData.headlinePuzzleCorrect
    if (this.currentUserService.currentUserData.headlinePuzzleCorrect) { this.audioPlayerService.correctSound.play() }
  }

}
