// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCuUCFZdpJecLWoW55XD6KJt85tD6NYAEY',
    authDomain: 'ldeo-escape-room.firebaseapp.com',
    databaseURL: 'https://ldeo-escape-room.firebaseio.com',
    projectId: 'ldeo-escape-room',
    storageBucket: 'ldeo-escape-room.appspot.com',
    messagingSenderId: '485694196640'
  }
}
