import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-puzzle-resource-guide',
  templateUrl: './puzzle-resource-guide.component.html',
  styleUrls: ['./puzzle-resource-guide.component.less']
})
export class PuzzleResourceGuideComponent implements OnInit {



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    console.log('MAIL')
    this.currentUserService.currentUserData.didViewMail = true
  }

}
