<div class="row">
  <div class="col-9">
    <div *ngIf="!currentUserService.currentUserData.doorPuzzleCorrect" class="mapImage">
    </div>
    <div *ngIf="currentUserService.currentUserData.doorPuzzleCorrect" class="mapGridImage">
      <div *ngIf="currentUserService.currentUserData.mapPuzzleCorrect" class="ship"></div>
      <div *ngIf="!currentUserService.currentUserData.mapPuzzleCorrect" class="mapAnswer" (click)="answerButtonPressed()"></div>
    </div>
  </div>
  <div class="col-3">

    <div class="clue">

      <p *ngIf="currentUserService.currentUserData.mapPuzzleCorrect">You've won a <b>TECTONIC PLATES PUZZLE PIECE</b>!</p>

      <p *ngIf="currentUserService.currentUserData.mapPuzzleCorrect"><img class="plate" src="/assets/images/plate_1.png" width="100%"/></p>

    </div>

  </div>
</div>
