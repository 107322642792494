import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puzzle-pigpen-key',
  templateUrl: './puzzle-pigpen-key.component.html',
  styleUrls: ['./puzzle-pigpen-key.component.less']
})
export class PuzzlePigpenKeyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
