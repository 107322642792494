<div class="mapImage"></div>

<a class="backButton" [routerLink]="['/game/newspaper']"><i class="fas fa-chevron-left"></i></a>

<div *ngIf="currentUserService.currentUserData.hudsonRiverPuzzleCorrect" class="pin pinMail" (click)="currentUserService.showPuzzle('resourceGuide')"></div>

<div *ngIf="currentUserService.currentUserData.didViewMail" class="pin pinRoom" [routerLink]="['/game/room']"></div>

<div *ngIf="currentUserService.hasPlate(0)" class="pin pinShip" [routerLink]="['/game/ship']"></div>

<div *ngIf="currentUserService.hasPlate(1)" class="pin pinJungle" [routerLink]="['/game/jungle']"></div>

<div *ngIf="currentUserService.hasPlate(2)" class="pin pinArctic" [routerLink]="['/game/arctic']"></div>

<div *ngIf="currentUserService.hasPlate(2)" class="pin pinCity" [routerLink]="['/game/city']"></div>

<div *ngIf="!currentUserService.currentUserData.hasHeat" class="pin pinHeat" (click)="currentUserService.collectHeat()"></div>
