import { Component, OnInit } from '@angular/core'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent implements OnInit {



  signUpValues = {
    email: '',
    password: ''
  }

  signInValues = {
    email: '',
    password: ''
  }

  signUpError: string
  signInError: string

  signInResetPasswordResponse: string



  constructor(
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit() {
  }

  signUp() {
    this.signInResetPasswordResponse = null
    this.currentUserService.signUp(this.signUpValues.email, this.signUpValues.password)
      .then(res => {
        this.signUpError = null
      }, err => {
        this.signUpError = err.message
      })
  }

  signIn() {
    this.signInResetPasswordResponse = null
    this.currentUserService.signIn(this.signInValues.email, this.signInValues.password)
      .then(res => {
        this.signInError = null
      }, err => {
        this.signInError = err.message
      })
  }

  resetPassword() {
    this.signInError = null
    this.signInResetPasswordResponse = null
    this.currentUserService.resetPassword(
      this.signInValues.email,
      () => {
        this.signInResetPasswordResponse = 'Great! Please check your email to reset your password.'
      },
      (error) => {
        this.signInResetPasswordResponse = 'Something went wrong. Are you sure you entered the right email address?'
      }
      )
  }

}
