<div class="codeImage"></div>

<div *ngIf="!currentUserService.currentUserData.codePuzzleCorrect" class="row">
  <div class="col-6">
  </div>
  <div class="col-6">

    <div class="clue">

      <p class="codeClue"><b>147</b> One digit is right but in the wrong place.</p>

      <p class="codeClue"><b>189</b> One digit is right and in its right place.</p>

      <p class="codeClue"><b>964</b> Two digits are correct but both are in the wrong place.</p>

      <p class="codeClue"><b>523</b> All digits are wrong.</p>

      <p class="codeClue"><b>286</b> One digit is right but in the wrong place.</p>

      <p><input [(ngModel)]="answer" /></p>

      <p><span class="btn btn-primary" (click)="answerButtonPressed()">Answer</span></p>

      <p *ngIf="isIncorrect">Try Again!</p>

    </div>

  </div>
</div>

<div *ngIf="currentUserService.currentUserData.codePuzzleCorrect" class="row">
  <div class="col">
    <iframe class="video" width="600" height="360" src="https://www.youtube-nocookie.com/embed/HhRpkIJaZEc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>
