<div class="fixedContainer">

  <div class="backgroundImage"></div>

  <form name="form" (ngSubmit)="f.form.valid && saveInfo()" #f="ngForm">

    <p>Please tell us a little about who's playing!</p>

    <h3>Team Name</h3>

    <p><input id="teamName" name="teamName" [(ngModel)]="currentUserService.currentUserData.teamName" /></p>

    <h3>Team Size</h3>

    <p><input id="teamSize" name="teamSize" [(ngModel)]="currentUserService.currentUserData.teamSize" /></p>

    <p><button type="submit" class="btn btn-primary">Save and Continue</button></p>

  </form>

</div>
