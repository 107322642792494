import { Component, OnInit } from '@angular/core'
import {CurrentUserService} from '../../../services/current-user.service'

@Component({
  selector: 'app-puzzle-memory',
  templateUrl: './puzzle-memory.component.html',
  styleUrls: ['./puzzle-memory.component.less']
})
export class PuzzleMemoryComponent implements OnInit {



  cards = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9]
  pick1Index = -1
  pick2Index = -1
  correctCards = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]

  didFindMatch = false
  noMatch = false



  constructor(
    public currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    this.shuffle(this.cards)
  }

  cardStyle(index) {
    if (this.pick1Index === index || this.pick2Index === index) {
      return `card_${this.cards[index]} cardPicked`
    } else if (this.correctCards[index]) {
      return `card_${this.cards[index]}`
    }
  }

  selectCard(index) {
    let doTimer = false
    if (this.correctCards[index]) { return }
    if (this.pick1Index === -1) {
      this.pick1Index = index
    } else if (this.pick2Index === -1 && this.pick1Index !== index ) {
      this.pick2Index = index
      doTimer = true
    }

    if (doTimer && this.pick1Index !== -1 && this.pick2Index !== -1) {
      if (this.cards[this.pick1Index] === this.cards[this.pick2Index]) {
        this.didFindMatch = true
        this.correctCards[this.pick1Index] = true
        this.correctCards[this.pick2Index] = true
        setTimeout(() => {
          this.didFindMatch = false
          this.resetPicks()
        }, 1000)
      } else {
        this.noMatch = true
        setTimeout(() => {
          this.noMatch = false
          this.resetPicks()
        }, 1000)
      }
    }
  }

  resetPicks() {
    this.pick1Index = -1
    this.pick2Index = -1

    let didWin = true

    this.correctCards.forEach((correctCard) => {
      if (!correctCard) { didWin = false }
    })

    if (didWin) {
      this.currentUserService.didAnswer('memoryPuzzleCorrect', true)
    }
  }

  shuffle(array) {
    let currentIndex = array.length
    let temporaryValue
    let randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

}
