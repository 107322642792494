import { ApplicationRef, Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { User } from '../models/user'
import * as confetti from 'canvas-confetti'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {



  public currentUser: any
  public currentUserData: User




  // State



  didShowPuzzle = false
  didShowHint = false

  puzzleIsVisible = false
  hintIsVisible = false
  hintRoom = ''
  visiblePuzzleType: string



  plateIsInPlace = [false, false, false, false, false]



  selectedItem = -1 // (0-3 in order of the above.)

  selectedWater = false
  selectedHeat = false
  selectedSalt = false
  selectedIce = false



  // Answers

  headlinePuzzleAnswer = 'and it shall teach thee'
  hudsonRiverPuzzleAnswer = 'fjord'

  computerPuzzleAnswer = 'strata'

  binocularsPuzzleAnswer = 'marcusglangseth'
  radioPuzzleAnswer = '168'
  soundwaveAnswers = [1500, 500, 1000, 1500, 1250, 2000, 1750, 1500, 1750, 2000]
  wheelPuzzleAnswer = '1.5'

  codePuzzleAnswer = '679'
  greenlandPuzzleAnswer = '4800000000000'

  treeRingsPuzzleAnswer = '16'



  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    private applicationRef: ApplicationRef
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.startUserStateListener()
  }

  async startUserStateListener() {

    await this.afAuth.onAuthStateChanged( async user => {
      if (user) {

        this.currentUser = user

        localStorage.setItem('currentUser', JSON.stringify(this.currentUser))

        // TODO: This is a mess.

        const observable = await this.getCurrentUser()
        observable.subscribe(async currentUserData => {
          this.currentUserData = currentUserData
          this.applicationRef.tick()
        })

      } else {
        this.currentUser = null
        localStorage.removeItem('currentUser')
      }

    })


  }

  signUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
  }

  signIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }

  signOut() {
    return this.afAuth.signOut()
  }

  resetPassword(email, didSucceed, failed) {
    this.afAuth.sendPasswordResetEmail(email).then(() => {
      didSucceed()
    }).catch((error) => {
      failed(error)
    })
  }

  async getCurrentUser() {

    const firebaseIdToken = await firebase.auth().currentUser.getIdToken(true)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      firebaseIdToken
    })

    return this.http.get<User>('/api/1.0/currentUser', {headers})

  }

  async postCurrentUser() {

    const firebaseIdToken = await firebase.auth().currentUser.getIdToken(true)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      firebaseIdToken
    })

    const user = this.currentUserData
    return this.http.post<User>(`/api/1.0/currentUser`, user, {headers}).pipe(
      map(currentUserData => {
        return new User(currentUserData)
      })
    )
  }

  async saveCurrentUser() {
    const observable = await this.postCurrentUser()
    observable.subscribe(user => {
      this.currentUserData = user
      console.log(this.currentUserData)
    })
  }




  // Game actions.

  showPuzzle(puzzleType) {
    this.didShowPuzzle = true
    this.puzzleIsVisible = true
    this.visiblePuzzleType = puzzleType
  }

  hidePuzzle() {
    this.puzzleIsVisible = false
    this.visiblePuzzleType = null
  }

  showHint(room) {
    this.didShowHint = true
    this.hintIsVisible = true
    this.hintRoom = room
  }

  hideHint() {
    this.hintIsVisible = false
  }

  hintDisplayClass() {
    if (!this.didShowHint) { return '' }
    return this.hintIsVisible ? 'hintIsVisible' : 'hintIsHidden'
  }

  hintShadeDisplayClass() {
    if (!this.didShowHint) { return '' }
    return this.hintIsVisible ? 'hintShadeIsVisible' : 'hintShadeIsHidden'
  }



  collectHeat() {
    this.currentUserData.hasHeat = true
    this.saveCurrentUser()
  }

  collectWater() {
    this.currentUserData.hasWater = true
    this.saveCurrentUser()
  }

  collectSalt() {
    this.currentUserData.hasSalt = true
    this.saveCurrentUser()
  }

  collectIce() {
    this.currentUserData.hasIce = true
    this.saveCurrentUser()
  }



  hasPlate(index) {
    switch (index) {
      case 0: return this.currentUserData.mapPuzzleCorrect
      case 1:
        return this.currentUserData.binocularsPuzzleCorrect &&
          this.currentUserData.radioPuzzleCorrect &&
          this.currentUserData.soundwavePuzzleCorrect &&
          this.currentUserData.wheelPuzzleCorrect
      case 2: return this.currentUserData.treeRingsPuzzleCorrect
      case 3: return this.currentUserData.codePuzzleCorrect && this.currentUserData.greenlandPuzzleCorrect
      case 4: return this.currentUserData.memoryPuzzleCorrect
      default: return false
    }
  }

  allPlatesInPlace() {
    for (let i = 0; i < 5; i++) {
      if (!this.plateIsInPlace[i]) { return false }
    }
    return true
  }



  didAnswer(which, isCorrect) {
    switch (which) {

      case 'headlinePuzzleCorrect':
        this.currentUserData.headlinePuzzleCorrect = isCorrect;
        break
      case 'hudsonRiverPuzzleCorrect':
        this.currentUserData.hudsonRiverPuzzleCorrect = isCorrect;
        break
      case 'didViewMail':
        this.currentUserData.didViewMail = isCorrect;
        break

      case 'doorPuzzleCorrect':
        this.currentUserData.doorPuzzleCorrect = isCorrect;
        break
      case 'computerPuzzleCorrect':
        this.currentUserData.computerPuzzleCorrect = isCorrect;
        break
      case 'mapPuzzleCorrect':
        this.currentUserData.mapPuzzleCorrect = isCorrect;
        break

      case 'binocularsPuzzleCorrect':
        this.currentUserData.binocularsPuzzleCorrect = isCorrect;
        break
      case 'radioPuzzleCorrect':
        this.currentUserData.radioPuzzleCorrect = isCorrect;
        break
      case 'soundwavePuzzleCorrect':
        this.currentUserData.soundwavePuzzleCorrect = isCorrect;
        break
      case 'wheelPuzzleCorrect':
        this.currentUserData.wheelPuzzleCorrect = isCorrect;
        break

      case 'codePuzzleCorrect':
        this.currentUserData.codePuzzleCorrect = isCorrect;
        break
      case 'greenlandPuzzleCorrect':
        this.currentUserData.greenlandPuzzleCorrect = isCorrect;
        break

      case 'treeRingsPuzzleCorrect':
        this.currentUserData.treeRingsPuzzleCorrect = isCorrect;
        break

      case 'memoryPuzzleCorrect':
        this.currentUserData.memoryPuzzleCorrect = isCorrect;
        break
    }

    this.saveCurrentUser()
  }



  cheat() {
    this.currentUserData.hasHeat = true
    this.currentUserData.hasWater = true
    this.currentUserData.hasSalt = true
    this.currentUserData.hasIce = true

    this.currentUserData.headlinePuzzleCorrect = true
    this.currentUserData.hudsonRiverPuzzleCorrect = true
    this.currentUserData.didViewMail = true

    this.currentUserData.doorPuzzleCorrect = true
    this.currentUserData.computerPuzzleCorrect = true
    this.currentUserData.mapPuzzleCorrect = true

    this.currentUserData.binocularsPuzzleCorrect = true
    this.currentUserData.radioPuzzleCorrect = true
    this.currentUserData.soundwavePuzzleCorrect = true
    this.currentUserData.wheelPuzzleCorrect = true

    this.currentUserData.codePuzzleCorrect = true
    this.currentUserData.greenlandPuzzleCorrect = true

    this.currentUserData.treeRingsPuzzleCorrect = true

    this.currentUserData.memoryPuzzleCorrect = true
  }

  resetGame() {
    this.currentUserData.hasHeat = false
    this.currentUserData.hasWater = false
    this.currentUserData.hasSalt = false
    this.currentUserData.hasIce = false

    this.currentUserData.headlinePuzzleCorrect = false
    this.currentUserData.hudsonRiverPuzzleCorrect = false
    this.currentUserData.didViewMail = false

    this.currentUserData.doorPuzzleCorrect = false
    this.currentUserData.computerPuzzleCorrect = false
    this.currentUserData.mapPuzzleCorrect = false

    this.currentUserData.binocularsPuzzleCorrect = false
    this.currentUserData.radioPuzzleCorrect = false
    this.currentUserData.soundwavePuzzleCorrect = false
    this.currentUserData.wheelPuzzleCorrect = false

    this.currentUserData.codePuzzleCorrect = false
    this.currentUserData.greenlandPuzzleCorrect = false

    this.currentUserData.treeRingsPuzzleCorrect = false

    this.currentUserData.memoryPuzzleCorrect = false

    this.saveCurrentUser()
  }



  showConfetti(canvas) {
    confetti.create(canvas, { resize: true }) ({
      shapes: ['circle'],
      particleCount: 400,
      spread: 300,
      origin: {
        y: (0.5),
        x: (0.5)
      }
    })
  }

}
